export const ADMIN_LOGIN = "/v1/admin/login";
export const ADMIN_FORGOT_PASSWORD = "/v1/admin/forgotPassword";
export const ADMIN_RESET_PASSWORD = "/v1/admin/resetPassword";
export const ADMIN_CONTENT_GETUSERTYPE = "/v1/admin/getUserTypeList";
export const ADMIN_CONTENT_GET_INDUSTRY = "/v1/admin/get-industry";
export const ADMIN_CONTENT_DELETE_INDUSTRY = "/v1/admin/delete-industry";
export const ADMIN_CONTENT_CREATE_INDUSTRY = "/v1/admin/create-industry";
export const ADMIN_CONTENT_EDIT_INDUSTRY = "/v1/admin/edit-industry";


export const ADMIN_USER_LISTS = "/v1/admin/get-users";

//interest-management
export const ADMIN_CONTENT_GET_INTEREST = "/v1/admin/get-interest";
export const ADMIN_CONTENT_CREATE_INTEREST = "/v1/admin/create-interest";
export const ADMIN_CONTENT_EDIT_INTEREST = "/v1/admin/edit-interest";
export const ADMIN_CONTENT_DELETE_INTEREST = "/v1/admin/delete-interest";

//experience management
export const ADMIN_CONTENT_GET_EXPERIENCE = "/v1/admin/get-experience";
export const ADMIN_CONTENT_CREATE_EXPERIENCE = "/v1/admin/create-experience";
export const ADMIN_CONTENT_EDIT_EXPERIENCE = "/v1/admin/edit-experience";
export const ADMIN_CONTENT_DELETE_EXPERIENCE = "/v1/admin/delete-experience";

// dashboard
export const ADMIN_CONTENT_GET_DASHBOARD = "/v1/admin/get-dashboard";
export const ADMIN_CONTENT_GET_GOAL = "/v1/admin/get-goal";
export const ADMIN_CONTENT_EDIT_GOAL = "/v1/admin/edit-goal";
export const ADMIN_CONTENT_DELETE_GOAL = "/v1/admin/delete-goal";
export const ADMIN_CONTENT_CREATE_GOAL = "/v1/admin/create-goal";



//user type management
export const ADMIN_CONTENT_GET_USERTYPE = "/v1/admin/getUserTypeList";
export const ADMIN_CONTENT_CREATE_USERTYPE = "/v1/admin/createUserType";
export const ADMIN_CONTENT_EDIT_USERTYPE = "/v1/admin/editUserTypeList";
export const ADMIN_CONTENT_DELETE_USERTYPE = "/v1/admin/deleteUserTypeList";


export const ADMIN_CONTENT_EDIT_CONTENT = "/v1/admin/edit-active";

//endorsement management

export const ADMIN_ENDORSEMENT_GET = "/v1/admin/get-goal";
export const ADMIN_ENDORSEMENT_EDIT = "/v1/admin/edit-goal";
export const ADMIN_ENDORSEMENT_DELETE = "/v1/admin/delete-goal";
export const ADMIN_ENDORSEMENT_CREATE = "/v1/admin/create-goal";




export const ADMIN_RESET_PASSWORD_DIRECTLY = "/admin/reset-password-directly";
export const TOTAL_NO_OF_USERS = "/admin/usersInfo";
export const FILTER_STATE = "/admin/userInfoStateFilter";
export const DISABLE_USER = "/admin/usersInfo";
export const FILTER_BY_DATE = "/admin/userInfoDateFilter";
export const REGISTERED_USERS_FILTER_BY_DATE =
  "/admin/all-users-count-date-filter";
export const FILTER_USERS_BY_NAME = "/admin/search-user-by-name";
export const BACK_TO_BASICS = "/admin/back-to-basics";
export const UPDATE_BACK_TO_BASICS = "/admin/update-back-to-basics";
export const DELETE_BACK_TO_BASICS = "/admin/delete-back-to-basics";
export const CREATE_BACK_TO_BASICS = "/admin/create-back-to-basics";
export const READY_TO_VOTE = "/admin/vote-information-details";
export const CREATE_VOTING_INFORMATION_FORM =
  "/admin/vote-information-details-created";

export const UPDATE_VOTE_FORMS = "/admin/vote-information-details-update";
export const DELETE_VOTE_FORMS = "/admin/vote-information-details-delete";
export const VOTE_FORMS = "/admin/vote-forms-details";
export const GET_ACCOUNTABILITY_GUIDE = "/admin/get-accountability-guide";
export const UPDATE_ACCOUNTABILITY_GUIDE = "/admin/update-accountability-guide";
export const DELETE_ACCOUNTABILITY_GUIDE = "/admin/delete-accountability-guide";
export const CREATE_ACCOUNTABILITY_GUIDE = "/admin/create-accountability-guide";
export const GET_MY_PRESIDENT_IS = "/admin/get-my-president-is";
export const UPDATE_MY_PRESIDENT_IS = "/admin/update-my-president-is";
export const DELETE_MY_PRESIDENT_IS = "/admin/delete-my-president-is";
export const CREATE_MY_PRESIDENT_IS = "/admin/create-my-president-is";
export const CREATE_READY_TO_VOTE_CONTENT =
  "/admin/create-ready-to-vote-content";
export const UPDATE_READY_TO_VOTE_CONTENT =
  "/admin/update-ready-to-vote-content";
export const DELETED_READY_TO_VOTE_CONTENT =
  "/admin/ready-to-vote-content-deleted";

export const GET_READY_TO_VOTE_CONTENT = "/admin/get-ready-to-vote-content";
export const GET_APP_NAME = "/admin/get-my-app-name";
export const EXPORT_DATA = "/admin/export-data";
export const GET_CANDIDATE_MATCH_UP = "/admin/get-candidate-match-up";
export const UPDATE_CANDIDATE_MATCH_UP = "/admin/update-candidate-match-up";

export const CREATE_CANDIDATE_MATCH_UP = "/admin/create-candidate-match-up";

export const DELETE_CANDIDATE_MATCH_UP = "/admin/delete-candidate-match-up";

export const LIST_ADVERTISEMENT = "/admin/listAdvertisement";
export const ADD_ADVERTISEMENT = "/admin/addAvertisement";
export const PUBLISHED_ADDVERTISEMENT = "/admin/publishedAdvertisement";
export const DELETE_ADDVERTISEMENT = "/admin/deleteAdvertisement";
export const FILTER_BY_ETHNICITY = "/admin/get-users-by-ethnicity";
export const FILTER_BY_DOB = "/admin/get-users-by-dob";
//referendums
export const REFERENDUMS = "/admin/get-referendums";

//civic-api
export const GET_ELECTION = "https://www.googleapis.com/civicinfo/v2/elections";
//Push Notifications

export const PUSH_PRESIDENT = "/admin/sendNotification";
export const PUSH_MATCH_UP_CANDIDATE = "/admin/sendNotification";
