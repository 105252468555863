import React, { forwardRef } from 'react';
const Button = forwardRef(
  ({ onClick, children, className = '', variant, disabled }, ref) => {
    console.log(disabled, "disabled")
    return (
      <button
        className={`lui-btn ${variant}-btn ${className}`}
        id={disabled ? "disabled-button":""}
        onClick={onClick}
        disabled={disabled}
        ref={ref}
      >
        {children}
      </button>
    );
  }
);

export default Button;
