import * as React from "react";
import {
  TextArea,
  Select,
  Input,
  ErrorField,
  Button as ChooseFile,
  Editor,
} from "..";
import {
  ethnicity,
  party,
  Options,
  stance,
  status,
} from "../../utils/constants";
import { UUIDGenerator, getLabel } from "../../utils/helper";
import AddIcon from "@mui/icons-material/Add";
import { Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { errorAvailable } from "../../redux/errorSlice";
import { produce } from "immer"; // Corrected import

export default function Modal(props) {
  const { formData, setFormData, errors, setErrors, isCandidateMatchUp } =
    props;

  const [mediaFiles, setMediaFiles] = React.useState({ img: "", video: "" });
  const dispatch = useDispatch();

  const getSelectData = (value) => {
    switch (value) {
      case "ethnicity":
        return Options;
      case "ethnicityType":
        return ethnicity;
      case "advtStatus":
        return status;
      case "stance":
        return stance;
      case "politicalParty":
        return party;
      default:
        return [];
    }
  };

  const addStancesHandler = () => {
    const guideStance = {
      stance: "",
      title: "",
      description: "",
      _id: "generator" + UUIDGenerator(),
    };

    const matchUpStance = {
      stance: "",
      title1: "",
      description1: "",
      title2: "",
      description2: "",
      _id: "generator" + UUIDGenerator(),
    };

    setFormData((prevFormData) => ({
      ...prevFormData,
      stanceContent: [
        ...prevFormData.stanceContent,
        isCandidateMatchUp ? matchUpStance : guideStance,
      ],
    }));
  };

  const getValue = (key, loc, nestedKey) => {
    if (
      typeof formData[key] === "object" &&
      key !== "politicalExperience" &&
      !Array.isArray(formData[key])
    ) {
      return formData?.[key]?.[loc];
    } else if (
      typeof formData[key] === "object" &&
      key === "politicalExperience" &&
      !Array.isArray(formData[key])
    ) {
      return formData[key][loc][nestedKey];
    } else if (Array.isArray(formData[key])) {
      const item = formData?.stanceContent?.find(
        (item) => item?._id === nestedKey
      );

      return item ? item[loc] : null;
    } else {
      return formData?.[key];
    }
  };

  const handleFormChange = ({ key, InnerKey, innerId, value }) => {
    let htmlContent = "<p><br></p>";
    setErrors({});
    setFormData((prevFormData) =>
      produce(prevFormData, (draft) => {
        if (prevFormData?.address && key in prevFormData?.address) {
          draft.address[key] = value === htmlContent ? "" : value;
        } else if (key === "politicalExperience") {
          if (!draft[key]) draft[key] = {};
          if (!draft[key][InnerKey]) draft[key][InnerKey] = {};
          draft[key][InnerKey][innerId] = value === htmlContent ? "" : value;
        } else if (Array.isArray(prevFormData?.[InnerKey])) {
          const item = draft[InnerKey].find((item) => item?._id === innerId);
          if (item) {
            item[key] = value === htmlContent ? "" : value;
          }
        } else {
          draft[key] = value === htmlContent ? "" : value;
        }
      })
    );
  };

  const getComponents = (value) => {
    if (
      value === "politicalParty" ||
      value === "advtStatus" ||
      value === "ethnicity" ||
      value === "ethnicityType"
    ) {
      return (
        <React.Fragment>
          <Select
            sx={{
              "&:focus": {
                border: "1px solid #804bdf",
              },
            }}
            label={getLabel(value)}
            name={getLabel(value)}
            value={getValue(value)}
            data={getSelectData(value)}
            className="select-custom-form"
            onChange={(e) => {
              handleFormChange({ key: value, value: e.target.value });
            }}
          />
          {errors?.hasOwnProperty(value) && (
            <ErrorField error={errors[value]} />
          )}
        </React.Fragment>
      );
    } else if (value === "address" && typeof formData[value] === "object") {
      return (
        <div className="address-edit-wrapper">
          {Object.keys(formData?.[value])
            ?.filter((item) => item !== "location")
            ?.map((location, index) => (
              <React.Fragment key={index}>
                <Input
                  type="text"
                  label={getLabel(location)}
                  name={getLabel(location)}
                  value={getValue(value, location)}
                  onChange={(e) =>
                    handleFormChange({
                      key: location,
                      value: e.target.value,
                    })
                  }
                />
                {errors?.hasOwnProperty(location) && (
                  <ErrorField error={errors[location]} />
                )}
              </React.Fragment>
            ))}
        </div>
      );
    } else if (
      formData[value] &&
      typeof Object.values(formData[value])?.[0] === "object" &&
      !Array.isArray(formData?.[value])
    ) {
      return (
        <>
          <div className="political-experience-heading">
            <h3 className="arrow">Political Experience</h3>
          </div>
          {Object.values(formData?.[value])?.map((item, index) => {
            const innerObjVal = Object.keys(item, index);

            return innerObjVal?.map((innerKey, innerIndex) => {
              return (
                <React.Fragment key={`${index}-${innerIndex}`}>
                  {innerKey === "description" ? (
                    <div className="text-area-wrapper-matchup">
                      <Editor
                        type="text"
                        className={innerKey}
                        placeholder={getLabel(innerKey)}
                        label={getLabel(innerKey)}
                        name={innerKey}
                        value={getValue(
                          value,
                          Object.keys(formData[value])[index],
                          innerKey
                        )}
                        onChange={(e) => {
                          handleFormChange({
                            key: value,
                            InnerKey: Object.keys(formData[value])[index],
                            innerId: innerKey,
                            value: e,
                          });
                        }}
                      />
                    </div>
                  ) : (
                    <Input
                      type="text"
                      label={getLabel(innerKey)}
                      name={innerKey}
                      value={getValue(
                        value,
                        Object.keys(formData[value])[index],
                        innerKey
                      )}
                      onChange={(e) => {
                        handleFormChange({
                          key: value,
                          InnerKey: Object.keys(formData[value])[index],
                          innerId: innerKey,
                          value: e.target.value,
                        });
                      }}
                    />
                  )}
                  {errors?.hasOwnProperty(
                    `${value}.${Object.keys(formData[value])[index]}.${
                      innerObjVal[innerIndex]
                    }`
                  ) && (
                    <ErrorField
                      error={
                        errors[
                          `${value}.${Object.keys(formData[value])[index]}.${
                            innerObjVal[innerIndex]
                          }`
                        ]
                      }
                    />
                  )}
                </React.Fragment>
              );
            });
          })}
        </>
      );
    } else if (value === "stanceContent" && Array.isArray(formData[value])) {
      return (
        <div className="address-edit-wrapper">
          {formData?.[value]?.map((content, Idx) => {
            return (
              <React.Fragment key={Idx}>
                <h3 className="address-heading arrow">{`${getLabel(
                  value
                )?.slice(0, -1)} ${Idx + 1}`}</h3>
                <div className="text-area-wrapper-modal" key={Idx}>
                  {Object.keys(content)?.map((location, index) => {
                    return (
                      <React.Fragment key={index}>
                        {location !== "_id" && (
                          <>
                            {location === "stance" ? (
                              <div className="stance-select">
                                <Select
                                  label={getLabel(location)}
                                  name={getLabel(location)}
                                  placeholder={getLabel(location)}
                                  data={stance}
                                  value={getValue(
                                    value,
                                    location,
                                    content["_id"]
                                  )}
                                  onChange={(e) =>
                                    handleFormChange({
                                      key: location,
                                      InnerKey: "stanceContent",
                                      innerId: content["_id"],
                                      value: e.target.value,
                                    })
                                  }
                                />
                                {errors?.hasOwnProperty(value) && (
                                  <ErrorField
                                    error={
                                      errors[`${value}[${Idx}].${location}`]
                                    }
                                  />
                                )}
                              </div>
                            ) : (
                              <>
                                {location === "title" ||
                                location === "title1" ||
                                location === "title2" ? (
                                  <div className="text-area-wrapper">
                                    <Input
                                      type="text"
                                      className={
                                        isCandidateMatchUp
                                          ? location.slice(0, -1)
                                          : location
                                      }
                                      label={getLabel(location)}
                                      name={getLabel(location)}
                                      placeholder={getLabel(location)}
                                      value={getValue(
                                        value,
                                        location,
                                        content["_id"]
                                      )}
                                      onChange={(e) =>
                                        handleFormChange({
                                          key: location,
                                          InnerKey: "stanceContent",
                                          innerId: content["_id"],
                                          value: e.target.value,
                                        })
                                      }
                                    />
                                    {errors && (
                                      <ErrorField
                                        error={
                                          errors?.[
                                            `${value}[${Idx}].${location}`
                                          ]
                                        }
                                      />
                                    )}
                                  </div>
                                ) : location === "description" ||
                                  location === "description1" ||
                                  location === "description2" ? (
                                  <div className="text-area-wrapper">
                                    <Editor
                                      className={
                                        isCandidateMatchUp
                                          ? location.slice(0, -1)
                                          : location
                                      }
                                      label={getLabel(location)}
                                      name={getLabel(location)}
                                      placeholder={getLabel(location)}
                                      value={getValue(
                                        value,
                                        location,
                                        content["_id"]
                                      )}
                                      onChange={(e) =>
                                        handleFormChange({
                                          key: location,
                                          InnerKey: "stanceContent",
                                          innerId: content["_id"],
                                          value: e,
                                        })
                                      }
                                    />
                                    {errors && (
                                      <ErrorField
                                        error={
                                          errors?.[
                                            `${value}[${Idx}].${location}`
                                          ]
                                        }
                                      />
                                    )}
                                  </div>
                                ) : (
                                  <div className="text-area-wrapper">
                                    <TextArea
                                      type="text"
                                      className={
                                        isCandidateMatchUp
                                          ? location.slice(0, -1)
                                          : location
                                      }
                                      label={getLabel(location)}
                                      name={getLabel(location)}
                                      placeholder={getLabel(location)}
                                      value={getValue(
                                        value,
                                        location,
                                        content["_id"]
                                      )}
                                      onChange={(e) =>
                                        handleFormChange({
                                          key: location,
                                          InnerKey: "stanceContent",
                                          innerId: content["_id"],
                                          value: e.target.value,
                                        })
                                      }
                                    />
                                    {errors && (
                                      <ErrorField
                                        error={
                                          errors?.[
                                            `${value}[${Idx}].${location}`
                                          ]
                                        }
                                      />
                                    )}
                                  </div>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </React.Fragment>
                    );
                  })}
                </div>
              </React.Fragment>
            );
          })}
          <div className="add_stance_wrapper" onClick={addStancesHandler}>
            <Button>
              Add more stances <AddIcon />
            </Button>
          </div>
        </div>
      );
    } else if (value === "media") {
      return (
        <div className="input-media-img-container">
          <label htmlFor="imageInput" className="choose-file">
            Choose Image
          </label>
          <Input
            type="file"
            accept="image/*"
            mediaAttribute={getLabel(value)}
            name={getLabel(value)}
            onChange={(e) => {
              const file = e.target.files[0];
              const imageURL = file && URL?.createObjectURL(file);
              setMediaFiles({ ...mediaFiles, img: imageURL });
              handleFormChange({ key: value, value: file });
            }}
          />
          {(mediaFiles?.img || getValue(value)) && (
            <img
              src={mediaFiles?.img ? mediaFiles?.img : getValue(value)}
              alt="media"
            />
          )}
          {errors?.hasOwnProperty(value) && (
            <ErrorField error={errors[value]} />
          )}
        </div>
      );
    } else if (value === "video") {
      return (
        <div className="input-media-img-container">
          <label htmlFor="imageInput" className="choose-file">
            Choose Video
          </label>
          <Input
            type="file"
            accept="video/*"
            mediaAttribute={getLabel(value)}
            name={getLabel(value)}
            onChange={(e) => {
              const file = e.target.files[0];
              const videoUrl = file && URL?.createObjectURL(file);
              setMediaFiles({ ...mediaFiles, video: videoUrl });
              const video = document.createElement("video");
              video.src = URL.createObjectURL(file);

              video.onloadedmetadata = () => {
                const duration = video.duration;
                if (duration > 60) {
                  dispatch(
                    errorAvailable(
                      "Please select video having duration below 1 minute"
                    )
                  );
                }
              };
              handleFormChange({ key: value, value: e.target.files[0] });
            }}
          />
          {(mediaFiles?.video || getValue(value)) && (
            <video autoPlay muted key={mediaFiles.video}>
              <source src={mediaFiles.video} type="video/mp4" />
            </video>
          )}
          {errors?.hasOwnProperty(value) && (
            <ErrorField error={errors[value]} />
          )}
        </div>
      );
    } else if (value === "description") {
      return (
        <div className="text-area-wrapper-matchup">
          <Editor
            className="description"
            placeholder={getLabel(value)}
            label={getLabel(value)}
            name={getLabel(value)}
            value={getValue(value)}
            onChange={(e) => handleFormChange({ key: value, value: e })}
          />
          {errors?.hasOwnProperty(value) && (
            <ErrorField error={errors[value]} />
          )}
        </div>
      );
    } else if (value === "experience") {
      return (
        <div className="text-area-wrapper-matchup">
          <TextArea
            type="text"
            className="description"
            placeholder={getLabel(value)}
            label={getLabel(value)}
            name={getLabel(value)}
            value={getValue(value)}
            onChange={(e) =>
              handleFormChange({ key: value, value: e.target.value })
            }
          />
          {errors?.hasOwnProperty(value) && (
            <ErrorField error={errors[value]} />
          )}
        </div>
      );
    } else {
      return (
        <React.Fragment>
          <Input
            type={value === "password" ? "password" : "text"}
            label={getLabel(value)}
            name={getLabel(value)}
            value={getValue(value)}
            onChange={(e) =>
              handleFormChange({ key: value, value: e.target.value })
            }
          />
          {errors?.hasOwnProperty(value) && (
            <ErrorField error={errors[value]} />
          )}
        </React.Fragment>
      );
    }
  };

  return (
    <div>
      {Object.keys(formData)?.length
        ? Object.keys(formData)
            ?.filter(
              (item) =>
                item !== "_id" &&
                item !== "id" &&
                item !== "__v" &&
                item !== "joiningDate" &&
                item !== "updatedAt"
            )
            ?.map((key) => (
              <React.Fragment key={key}>{getComponents(key)}</React.Fragment>
            ))
        : null}
    </div>
  );
}
