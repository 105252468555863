import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import LocationSearchingIcon from "@mui/icons-material/LocationSearching";
import PersonIcon from "@mui/icons-material/Person";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import VideoCameraBackIcon from "@mui/icons-material/VideoCameraBack";

import Icons from "../components/icons";

export const dateConvertor = (value) => {
  const dateOfJoining = new Date(value);

  const day = dateOfJoining?.getDate();
  let daySuffix;
  if (day >= 11 && day <= 13) {
    daySuffix = "th";
  } else {
    switch (day % 10) {
      case 1:
        daySuffix = "st";
        break;
      case 2:
        daySuffix = "nd";
        break;
      case 3:
        daySuffix = "rd";
        break;
      default:
        daySuffix = "th";
        break;
    }
  }

  const options = { year: "numeric", month: "long", day: "numeric" };
  const formattedDate = dateOfJoining?.toLocaleDateString("en-US", options);
  return formattedDate.replace(/\d{1,2}/, (match) => match + daySuffix);
};

export const newUsersAdded = (users) => {
  const newMontlyUsers = users?.filter(
    (item) =>
      new Date(item?.dateOfJoinig)?.getMonth() === new Date().getMonth() &&
      new Date(item?.dateOfJoinig)?.getFullYear() === new Date().getFullYear()
  );
  if (users) {
    if (!users.length) return 0;

    const newUsersCount = newMontlyUsers?.length;
    const percentage = (newUsersCount / users?.length) * 100;
    return percentage.toFixed(2);
  }
};
const handleIconClick = () => {
  document.getElementById("fileInput").click();
};
const handleVideoClick = () => {
  document.getElementById("fileVideoInput").click();
};
const style = { cursor: "pointer" };
export const getIcon = (type, state, fnc) => {
  console.log(type, "type");
  switch (type) {
    case "Date":
      return <CalendarMonthIcon style={style} />;
    case "Search":
      return <Icons name="search" style={style} />;
    case "Image":
      return (
        <>
          <CameraAltIcon onClick={handleIconClick} style={style} />
          <input
            hidden
            type="file"
            id="fileInput"
            onChange={fnc}
            accept="image/*"
          />
        </>
      );
    case "Video Upload":
      return (
        <>
          {" "}
          <VideoCameraBackIcon onClick={handleVideoClick} style={style} />{" "}
          <input
            hidden
            type="file"
            id="fileVideoInput"
            onChange={fnc}
            accept="video/*"
          />
        </>
      );
    case "Password":
    case "New Password":
    case "Confirm Password":
    case "Old Password":
      return (
        <IconButton onClick={fnc} edge="end">
          {state ? (
            <VisibilityIcon style={style} />
          ) : (
            <VisibilityOffIcon style={style} />
          )}
        </IconButton>
      );
    case "State":
      return <LocationSearchingIcon style={style} />;
    case "Name":
      return <PersonIcon style={style} />;
    default:
      return null;
  }
};

export const getLabel = (label) => {
  switch (label && label.toUpperCase()) {
    case "USERNAME":
      return "Name";
    case "REGISTRATION DATE":
      return "Registration Date";
    case "EMAIL":
      return "Email";
    case "USERTYPE":
      return "Type";
    case "MODE OF SIGNUP":
      return "Mode";
    case "STATUS":
      return "Status";
    default:
      return label;
  }
};
export const getAddress = (value) => {
  const address = Object.values(value)?.filter((item) => Boolean(item));

  return address?.length ? address.join(" ") : "N/A";
};
export const UUIDGenerator = () => {
  const part1 = Math.random().toString(36).substr(2, 8); // 16 characters
  const part2 = Math.random().toString(36).substr(2, 8); // 16 characters

  const uuid = +part1 + part2;

  return uuid;
};

export const createYearArray = () => {
  const currentYear = new Date().getFullYear();
  const startYear = 1950;
  const yearArray = [];

  for (let year = startYear; year <= currentYear; year++) {
    yearArray.push(year);
  }

  return yearArray.sort((a, b) => b - a);
};

export const titleSplice = (value, max = 150) => {
  return value?.length > max ? value.substring(0, max) + "..." : value;
};

export function ensureHttps(url) {
  // Check if the URL starts with 'http://'
  if (url.startsWith("http://")) {
    // Replace 'http://' with 'https://'
    return url.replace("http://", "https://");
  }
  // Check if the URL doesn't start with either 'http://' or 'https://'
  else if (!url.startsWith("https://")) {
    // Prepend 'https://' to the URL
    return "https://" + url;
  }
  // If the URL already starts with 'https://', return it as is
  return url;
}

export const capitalized = (val1, val2) => {
  console.log(val1, val2, "12345");

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const capitalizedVal1 = capitalizeFirstLetter(val1);
  const capitalizedVal2 = capitalizeFirstLetter(val2);

  return capitalizedVal1 + " " + capitalizedVal2;
};
