import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Input from "../input";
import { mutationAPI } from "../../api/services";
import { ADMIN_CONTENT_DELETE_INDUSTRY, ADMIN_CONTENT_GET_INDUSTRY } from "../../api/constants";
import CrossIcon from "@mui/icons-material/Clear";


export default function DeleteModal(props) {
  const { isOpen, close, isDisable,data,setData,DeleteUrl,GetUrl } = props;


  const submitHandler=async()=>{

    try {
  
    const response = await mutationAPI(DeleteUrl, "DELETE", {_id:data?._id});
   const res = await mutationAPI(GetUrl, "GET",{});
   setData(res?.data?.data)
   close()   
  } catch (error) {
      console.log({error})
  }finally{
    close()  
  }
 }
  return (
    <React.Fragment>
      <Dialog
        open={isOpen}
        onClose={close}
        fullWidth
        maxWidth="sm"
        disableBackdropClick={true}
      >
        <DialogTitle className="dialog_title">Delete
        <CrossIcon             onClick={() => close()}
        />
        </DialogTitle>
        <DialogContent>
<div>
<p style={{fontWeight:"600",fontSize:"16px",paddingTop:"1rem"}}>Are your sure want to delete?</p>
</div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => close()}
            sx={{
              fontWeight:600,

              textTransform:"capitalize",
              background: "Black",
              color: "#fff",
              "&:hover": {
                background: "Black",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={submitHandler}
            disabled={isDisable}
            sx={{
              fontWeight:600,
              textTransform:"capitalize",

              background: isDisable? "grey": "#c9ff00",
              cursor: isDisable? "not-allowed": "pointer",
              color: "black !important",
              "&:hover": {
                background: isDisable? "grey": "#c9ff00",
              },
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
