import React from "react";
import logo from "../../assets/initial_logo.png";
const CustomLoader = () => {
  return (
    <div className="fallback-loader">
      <div>
        {/* <img src={logo} alt="" /> */}
        Loading
        <span>...</span>
      </div>
    </div>
  );
};

export default CustomLoader;
