import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Input from "../input";
import { mutationAPI } from "../../api/services";
import { ADMIN_CONTENT_CREATE_EXPERIENCE, ADMIN_CONTENT_CREATE_INDUSTRY, ADMIN_CONTENT_EDIT_EXPERIENCE, ADMIN_CONTENT_EDIT_INDUSTRY, ADMIN_CONTENT_GET_EXPERIENCE, ADMIN_CONTENT_GET_INDUSTRY } from "../../api/constants";

import CrossIcon from "@mui/icons-material/Clear";
import Select from "../select/Select";
import { Options } from "../../utils/constants";

export default function ModalExperience(props) {
  const { isOpen,isEdit, close, data, title, isDisable ,setData} = props;


  const filter = Options?.filter((item)=>item?.value ===data?.description)
  const [selected, setSelected] = React.useState(filter?.[0]?.key||"");

  
  const [titleVal,setTitleVal]=React.useState(data?.title||"")
  
  const submitHandler=async()=>{
    const payload={
      title:titleVal,
      description:`${selected} years`
    }
    if (data?._id) {
      payload._id = data._id;
    }
    if(isEdit){
      try {
        
    
      const response = await mutationAPI(ADMIN_CONTENT_EDIT_EXPERIENCE, "PATCH",payload);

      if(response?.status==200){
      const data =await mutationAPI(ADMIN_CONTENT_GET_EXPERIENCE, "GET",{});
      setData(data?.data?.data)
    }
      close()
    } catch (error) {
      close()

    }
    }else{
      try {
        
     
      const response = await mutationAPI(ADMIN_CONTENT_CREATE_EXPERIENCE, "POST",payload);
      console.log({response})
      if(response?.status==200){
        const data =await mutationAPI(ADMIN_CONTENT_GET_EXPERIENCE, "GET",{});
        setData(data?.data?.data)      }      close()

    } catch (error) {
      close()

    }
    }

  }

  const handleValueChange = (event) => {
  setSelected(event.target.value)
  };

return (
    <React.Fragment>
      <Dialog
        open={isOpen}
        onClose={close}
        fullWidth
        maxWidth="sm"
        disableBackdropClick={true}
      >
        <DialogTitle className="dialog_title">{isEdit ? "Edit Experience ":"Create Experience"}

          <CrossIcon             onClick={() => close()}
          />
        </DialogTitle>
        <DialogContent>
          <div style={{display:"flex",flexDirection:"column",gap:"15px",paddingTop:"1rem"}}>
     
            <div>
<p>Title</p>
        <Input
              type="text"
              label=""
              name="name"
              value={titleVal}
              onChange={(e)=>setTitleVal(e?.target?.value)}
            /></div>
            <div>
            <Select
              label="Years"
              value={selected}
              onChange={handleValueChange}
              data={Options}
            />
            </div>
            </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => close()}
            sx={{
              fontWeight:600 ,

              textTransform:"capitalize",

              background: "Black",
              color: "#fff",
              "&:hover": {
                background: "Black",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={submitHandler}
            disabled={isDisable}
            sx={{
              textTransform:"capitalize",
fontWeight:600 ,
              background: isDisable? "grey": "#c9ff00",
              cursor: isDisable? "not-allowed": "pointer",
              color: "black !important",
              "&:hover": {
                background: isDisable? "grey": "#c9ff00",
              },
            }}
          >
            {isEdit?"Update":"Create"}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
